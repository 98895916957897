import { ChangeEvent, CSSProperties } from "react";
import { IRow, TOnChangeRows } from "../components/ManagementTable/types";
import { EModalSizes } from "../components/Modal";
import { EUserRoleName } from "../services/roles/roleTypes";

export enum EPageLinks {
  "Home" = "/",
  "SignIn" = "/sign-in",
  "Dashboard" = "/dashboard",
  "ForgotPassword" = "/forgot-password",
  "SignUp" = "/sign-up",
  "StandardProducts" = "/standard-products",
  "NonStandardProducts" = "/non-standard-products",
  "ProductVirtual" = "/product-virtual",
  "BOQExcel" = "/boq-excel",
  "Projectlist" = "/project-list",
  "BOQComparison" = "/boq-comparison/:id",
  "BOQComparisonCostAndPrice" = "/boq-comparison-cost-price/:id",
  "BOQManagement" = "/boq-management",
  "BOQData" = "/boq-data",
  "ProductBeamAngle" = "/product-beam-angle",
  "ProductBeamDiffuser" = "/product-beam-diffuser",
  "ProductCg" = "/profuct-cg",
  "ProductCe" = "/product-ce",
  "ProductCri" = "/product-cri",
  "ProductCt" = "/product-ct",
  "ProductDia" = "/product-dia",
  "ProductDiffuser" = "/product-diffuser",
  "ProductDirectIndirect" = "/product-direct-indirect",
  "ProductFinish" = "/product-finish",
  "ProductFixture" = "/product-fixture",
  "ProductFlux" = "/product-flux",
  "ProductGroup" = "/product-group",
  "ProductHc" = "/product-hc",
  "ProductHeight" = "/product-height",
  "ProductIk" = "/product-ik",
  "ProductIp" = "/product-ip",
  "ProductKelvin" = "/product-kelvin",
  "ProductLc" = "/profuct-lc",
  "ProductLdc" = "/profuct-ldc",
  "ProductLength" = "/profuct-length",
  "ProductLm" = "/profuct-lm",
  "ProductMaterial" = "/product-material",
  "ProductName" = "/product-name",
  "ProductPhotobiological" = "/product-photobiological",
  "ProductPower" = "/product-power",
  "ProductProfile" = "/product-profile",
  "ProductSdcm" = "/product-sdcm",
  "ProductSensor" = "/product-sensor",
  "ProductShape" = "/product-shape",
  "ProductType" = "/product-type",
  "ProductUgr" = "/product-ugr",
  "GroupFamilySubFamily" = "/group-family-subfamily",
  "NotFound" = "/404",
  "Exception" = "*",
  "BOQManagement1" = "/project/edit/boq-management/:projectId",
  "BOQData1" = "/project/edit/boq-data/:projectId/*",
  "PreSpecification" = "/project/edit/pre-specification/:projectId",
  "FinalSpecification" = "/project/edit/final-specification/:projectId",
  "Commercials" = "/project/edit/commercials/:projectId/*",
  "OrderBook" = "/project/edit/order-book/:projectId/*",
  "Quotation" = "/project/edit/quotation/:projectId/*",
  "Sampling" = "/project/edit/sampling/:projectId/*",
  "Design" = "/project/edit/design/:projectId/*",
  "Handover" = "/project/edit/handover/:projectId/*",
  "ProjectPlan" = "/project/edit/project-plan/:projectId/*",
  "SupplyChain" = "/project/edit/supply-chain/:projectId/*",
  "ProjectSupport" = "/project/edit/project-support/:projectId/*",
  "Warranty" = "/project/edit/warranty/:projectId/*",
  "ProductImageTypeMaster" = "/product-image-type-master",
  "ProductFileTypeMaster" = "/product-file-type-master",
  "Billingmethod" = "/billing-method",
  "City" = "/city",
  "State" = "/state",
  "Country" = "/country",
  "Branch" = "/branch",
  "Stage" = "/stage",
  "Operation" = "/operation",
  "IndoorOutdoor" = "/indoor-outdoor",
  "ProductManagement" = "/product-management",
  "ProductAdd" = "/products/add",
  "ProductEdit" = "/products/edit/:productId",
  "UserManagement" = "/user-management",
  "Users" = "/users",
  "Roles" = "/roles",
  "Permissions" = "/permissions",
  "ProjectManagement" = "/project-management",
  "Projects" = "/project-management/projects",
  "Projects1" = "/project-management/project-list",
  "ProjectAdd" = "/project-management/project/add",
  "ProjectEdit" = "/project-management/project/edit/:projectId/information",
  "ProjectOldAdd" = "/project-old-management/project/add",
  "ProjectOldEdit" = "/project-old-management/project/edit/:projectId/information",
  "MaterialManagement" = "/material-management",
  "VariationManagement" = "/variation-management",
  "Attributes" = "/material-management/attributes",
  "AttributesAdd" = "/material-management/attributes/add",
  "AttributesEdit" = "/material-management/attributes/edit/:attributeId",
  "FormComponents" = "/material-management/form-components",
  "FormComponentsAdd" = "/material-management/form-components/add",
  "FormComponentsEdit" = "/material-management/form-components/edit/:formComponentId",
  "AttributeCategory" = "/material-management/attribute-category",
  "FormComponentCategory" = "/material-management/form-component-Category",
  "Components" = "/material-management/components",
  "ComponentsBill" = "/material-management/component-bill",
  "ComponentsBillAdd" = "/material-management/component-bill/add",
  "ComponentsBillEdit" = "/material-management/component-bill/edit/:componentBillId",
  "ComponentDetails" = "/material-management/components/:componentTitle",
  "Requisition" = "/production-management/requisition",
  "RequisitionAdd" = "/production-management/requisition/add",
  "RequisitionEdit" = "/production-management/requisition/edit/:requisitionId",
  "RequisitionView" = "/production-management/requisition/add",
  "SpecifierType" = "/production-management/specifier-type",
  "PrivateNumber" = "/private-number",
  "PrivateDetailed" = "/private-detailed",
  "PrivateDevelopment" = "/private-development",
  "GovernmentNumber" = "/government-number",
  "GovernmentDevlopment" = "/government-devlopment",
  "GovernmentDetailed" = "/government-detailed",
  "ResidentialNumber" = "/residential-number",
  "ResidentialDevlopment" = "/residential-devlopment",
  "ResidentialDetailed" = "/residential-detailed",
  "RegentNumber" = "/regent-number",
  "RegentDevelopment" = "/regent-development",
}

export enum EBackendPageRouterLinks {
  "Home" = "/dashboard",
  "SignIn" = "/sign-in",
  "Dashboard" = "/dashboard",
  "ForgotPassword" = "/forgot-password",
  "SignUp" = "/sign-up",
  "UserManagement" = "/user-management",
  "TallySyncViewer" = "/tally-sync-viewer",
  "ProductManagement" = "/product-management",
  "ProjectOldManagement" = "/project-old-management",
  "ProjectManagement" = "/project-management",
  "MasterDatabase" = "/master-database",
  "OutgoingQuality" = "/outgoing-quality",
  "SalesManagement" = "/sales-management",
  "ChartLibraries" = "/chart-libraries",
  "OldPortalManagement" = "/old-portal-management",
  "MaterialManagement" = "/material-management",
  "VariationManagement" = "/variation-management",
  "LogisticsManagement" = "/logistics-management",
  "ProductionPlanManagement" = "/production-plan-management",
  "ProductionPlanDashboard" = "/production-plan-dashboard",
  "ProductionPlanSummary" = "/production-plan-summary",
  "Packaging" = "/package/*",
  "Users" = "/users",
  "IssuanceSlip" = "/issuance-slip",
  "ProductionSlip" = "/production-slip",
  "MrnSlip" = "/mrn-slip",
  "Replacement" = "/replacement",
  "ReportReplacement" = "/report-replacement",

  "Roles" = "/roles",

  "MatrialReceiveNote" = "/matrial-receive-note",
  "MRNAccount" = "/mrn-account",
  "QualityCheck" = "/quality-check/*",
  "MrnDashboard" = "/mrn-dashboard",
  "MrnComponentWiseDetails" = "/mrn-component-wise-details",
  "Permissions" = "/permissions",
  "VariationProductRequests" = "/variation-product-requests",
  "VariationProductClosed" = "/variation-product-closed",
  "FinalProductChangeRequests" = "/final-product-change-requests",
  "StandardProducts" = "/standard-products",
  "NonStandardProducts" = "/non-standard-products",
  "ProductVirtual" = "/product-virtual",
  "BOQExcel" = "/boq-excel",
  "BOQComparison" = "/boq-comparison/:id",
  "BOQComparisonCostAndPrice" = "/boq-comparison-cost-price/:id",
  "BOQComparisonVariations" = "/boq-comparison-variations/:id",
  "BOQManagement" = "/boq-management",
  "BOQData" = "/boq-data",
  "ProductBeamAngle" = "/product-beam-angle",
  "ProductBeamDiffuser" = "/product-beam-diffuser",
  "ProductCg" = "/profuct-cg",
  "ProductCe" = "/product-ce",
  "ProductCri" = "/product-cri",
  "ProductCt" = "/product-ct",
  "ProductDia" = "/product-dia",
  "ProductDiffuser" = "/product-diffuser",
  "ProductDirectIndirect" = "/product-direct-indirect",
  "ProductFamily" = "/product-family",
  "ProductSubFamily" = "/product-sub-family",
  "ProductFinish" = "/product-finish",
  "ProductFixture" = "/product-fixture",
  "ProductFlux" = "/product-flux",
  "ProductGroup" = "/product-group",
  "ProductHc" = "/product-hc",
  "ProductHeight" = "/product-height",
  "ProductIk" = "/product-ik",
  "ProductIp" = "/product-ip",
  "ProductKelvin" = "/product-kelvin",
  "ProductLc" = "/profuct-lc",
  "ProductLdc" = "/profuct-ldc",
  "ProductLength" = "/profuct-length",
  "ProductLm" = "/profuct-lm",
  "ProductMaterial" = "/product-material",
  "ProductName" = "/product-name",
  "ProductPhotobiological" = "/product-photobiological",
  "ProductPower" = "/product-power",
  "ProductProfile" = "/product-profile",
  "ProductSdcm" = "/product-sdcm",
  "ProductSensor" = "/product-sensor",
  "ProductShape" = "/product-shape",
  "ProductType" = "/product-type",
  "ProductUgr" = "/product-ugr",
  "GroupFamilySubFamily" = "/group-family-subfamily",
  "ProductAdd" = "/standard-products/add",
  "ProductAtomationAdd" = "/standard-products/add",
  "ProductStepTabsForm" = "/step-tabs-form/add",
  "ProductEdit" = "/standard-products/edit/:productId",
  "ProductionPlanAdd" = "/production-plan/Add",
  "ReplacementPlanAdd" = "/replacement-plan/Add",
  "SamplePlanAdd" = "/sample-plan/Add",
  "MrnEdit" = "/mrn-account/edit/:grnId",
  // "AutomationGeneratedProductADD" = "/automation-batch/add",
  "AutomationGeneratedProductLaunch" = "/automation-batch/launch/:Id",
  "AutomationGeneratedProduct" = "/automation-batch",
  "GeneratedVirtualProduct" = "/generated-virtual-product",
  "TdsDescriptionTemplate" = "/tds-description-template",

  "ProductAddByVariation" = "/variation-product-requests/add/:variationId",
  "ProductFinalAddByVariation" = "/final-product-change-requests/add/:projectSpecificationId",
  "NotFound" = "/404",
  "Exception" = "*",
  "Projects" = "/projects",
  "Projectlist" = "/project-list/*",
  "ProjectAdd" = "/project/add",
  "ProjectEdit" = "/project/edit/:projectId/information",
  "ProjectOldAdd" = "/project-old/add",
  "ProjectOldEdit" = "/project-old/edit/:projectId/information",
  "BOQManagement1" = "/boq-management",
  "BOQData1" = "/boq-data/*",
  "PreSpecification" = "/pre-specification",
  "FinalSpecification" = "/final-specification",
  "OrderBooks" = "/order-book",
  "DispatchBooks" = "/dispatch-book",
  "DispatchVOrder" = "/dispatch-order",
  "ComponentWiseDetails" = "/component-wise-details",
  "PendingDetails" = "/pending-details",
  "SampleBooks" = "/sample-book",
  "ReplacementBooks" = "/replacement-book",
  "OneDriveAll" = "/one-drive-all",
  "BOMRMReport" = "/bom-rm-report/*",
  "ProductionPlanReport" = "/production-plan-report/",
  "ReplacementIndent" = "/replacement-indent",
  "SampleIndent" = "/sample-indent",
  "LogisticDashboard" = "/logistic-dashboard",
  "SampleIndentDetail" = "/sample-indent-detail/:id",
  "Commercials" = "/commercials/*",
  "OrderBook" = "/order-book/*",
  "SampleBook" = "/sample-book/*",
  "ProductionPlan" = "/production-plan/*",
  "SamplePlan" = "/sample-plan/*",
  "ReplacementPlan" = "/replacement-plan/*",
  "ReplacementBook" = "/replacement-book/*",
  "Task" = "/task/*",
  "CommercialIndent" = "/commercial-indent/*",
  "CommercialsIndent" = "/commercials-indent",
  "CommercialIndentDetail" = "/commercial-indent-detail/:id",
  "ReplacementIndentDetail" = "/replacement-indent-detail/:id",
  "PurchaseOrderDetail" = "/purchase-order-detail/:id",
  "VendorPurchaseOrderDetail" = "/vendor-purchase-order-detail/:id",
  "VirtualProduct" = "/product-virtual/:id",
  "GroupPurchaseOrderDetail" = "/group-purchase-order-detail/:id",
  "WarrantyCertificate" = "/warranty-certificate/:id",
  "TestCertificate" = "/test-certificate/:id",
  "ProductFullDetail" = "/product-full-detail/:id",
  "BomFormationDetail" = "/bom-formation-detail/:id",
  "MatrialReceiptNote" = "/matrial-receipt-note/:id",
  "MatrialReceiptNoteCertificate" = "/matrial-receipt-note-certificate/:id",
  "MatrialReceiptNoteCertificateMultiple" = "/matrial-receipt-note-certificate-multiple/:id",
  "QualityReceiptNote" = "/quality-receipt-note/:id",
  "MRN" = "/mrn",
  "Indent" = "/indent/*",
  "Sample" = "/sample",
  "Quotation" = "/quotation/*",
  "Sampling" = "/sampling/*",
  "Design" = "/design/*",
  "Handover" = "/handover/*",
  "ProjectPlan" = "/project-plan/*",
  "SupplyChain" = "/supply-chain",
  "ProjectSupport" = "/project-support/*",
  "Warranty" = "/project/s/:projectId/warranty/*",
  "Attributes" = "/attributes",
  "AttributesAdd" = "/attributes/add",
  "AttributesEdit" = "/attributes/edit/:attributeId",
  "FormComponents" = "/form-components",
  "FormComponentsAdd" = "/form-components/add",
  "FormComponentsEdit" = "/form-components/edit/:formComponentId",
  "AttributeCategory" = "/attribute-category",
  "FormComponentCategory" = "/form-component-Category",
  "Components" = "/components",
  "ComponentsBill" = "/component-bill",
  "AllComponent" = "/all-component",
  "ComponentsBillAdd" = "/component-bill/add",
  "ComponentsBillEdit" = "/component-bill/edit/:componentBillId",
  "ComponentDetails" = "/components/:componentTitle",
  "ProductImageTypeMaster" = "/product-image-type-master",
  "ProductFileTypeMaster" = "/product-file-type-master",
  "BillingMethod" = "/billing-method",
  "City" = "/city",
  "State" = "/state",
  "Country" = "/country",
  "Branch" = "/branch",
  "Stage" = "/stage",
  "Operation" = "/operation",
  "IndoorOutdoor" = "/indoor-outdoor",
  "Website" = "/website-management",
  "WarehouseViewer" = "/warehouse-viewer",
  "Report" = "/report",
  "ReportFinalSpecification" = "/final-specification",
  "AllStore" = "/all-store",
  "WebsiteConfiguration" = "/configuration",
  "WebsiteManage" = "/Manage",
  "WebsiteConfigurationHeader" = "/config-header",
  "WebsiteUser" = "/users",
  "WebsiteNewsletter" = "/newsletters",
  "WebsiteContactUs" = "/contact-us",
  "WebsiteCMS" = "/content-management-system",
  "WebsiteCMSHome" = "/cms-home",
  "WebsiteCMSHistory" = "/cms-history",
  "WebsiteCMSCooperation" = "/cooperation",
  "WebsiteCMSCooperationCategory" = "/cooperation-category",
  "WebsiteCMSPages" = "/cms-pages",
  "WebsiteSections" = "/sections",
  "WebsiteSlider" = "/slider",
  "WebsiteCMSCustomerService" = "/customer-service",
  "WebsiteTeam" = "/team",
  "WebsitePageBanner" = "/page-banner",
  "WebsiteManagement" = "WebsiteManagement",
  "VendorSection" = "/vendor-section",

  "VendorManagement" = "/vendor-management",
  "Vendor" = "/vendor-section",
  "Vendors" = "/vendors",
  "VendorComponent" = "/vendor-component",
  "WebsiteConfigurationSlug" = "/slug",
  "WebsiteConfigurationSocialIcon" = "/social-icon",
  "Stock" = "/stock",
  "PurchaseOrder" = "/purchase-order",
  "RmStock" = "/rm-stock",
  "PurchaseOrderTracking" = "/purchase-order-tracking",
  "PurchaseOrderDashboard" = "/purchase-order-dashboard",
  "PurchaseOrderComponentMapping" = "/purchase-order-component-mapping",
  "PurchaseOrderComponentMappingReceived" = "/purchase-order-component-mapping-received",
  "Pending" = "/pending",
  "Received" = "/received",
  "Bom" = "/bom",
  "UnfreezeRequest" = "/unfreeze-request",
  "UpdateAnalysis" = "/update-analysis",
  "MDSProjectWise" = "/mds-project-wise",
  "BomRMAnalysis" = "/bom-rm-analysis",
  "BomFormation" = "/bom-formation",
  "BomFormationReplacement" = "/bom-formation-replacement",
  "BomFormationSample" = "/bom-formation-sample",
  "BomFormationRD" = "/bom-formation-rd",
  "ChangeRequest" = "/change-request",
  "ProcessedRequest" = "/processed-request",
  "Dispatched" = "/dispatched",
  "Mapping" = "/mapping",
  "BomFormationDispatched" = "/bom-formation-dispatched",
  "BomFormationRmStockMapping" = "/bom-formation-rm-stock-mapping",
  "Analysis" = "/analysis",
  "ActionHub" = "/action-hub",
  "Account" = "/account",
  "ComponentAnalyzer" = "/component-analyzer",
  "Crm" = "/crm",
  "CrmComponentWiseDetails" = "/crm-componentWise-details",
  "Engineering" = "/engineering",
  "Gate" = "/gate",
  "GateEntry" = "/gate-entry",
  "PurchaseManagement" = "/purchase-management",
  "StoreManagement" = "/store-management",
  "ProductionManagement" = "/production-management",
  "Requisition" = "/requisition",
  "RequisitionAdd" = "/requisition/add",
  "RequisitionEdit" = "/requisition/edit/:requisitionId",
  "RequisitionRmIssueDetail" = "/production-management/requisition-rm-issue-detail/:id",
  "RequisitionIssues" = "/requisition-rm-issue-detail/:id",
  "StoreRequisitions" = "/store-requisitions",
  "RequisitionRmIssue" = "/store-management/store-requisitions-rm-issues/:id",
  "StoreRequisitionsIssues" = "/store-requisitions-rm-issues/:id",
  "Consumptions" = "/consumptions",
  "ConsumptionConsumeDetail" = "/production-management/consumption-consume-detail/:id",
  "ConsumptionConsumeIssues" = "/consumption-consume-detail/:id",

  "SpecifierBranch" = "/specifier-branch",
  "SpecifierCompany" = "/specifier-company",
  "SpecifierType" = "/specifier-type",
  "SpecifierLevel" = "/specifier-level",
  "Region" = "/region",
  "Sector" = "/sector",
  "Segment" = "/segment",
  "OneDrive" = "/one-drive",
  "FinancialYear" = "/financial-year",
  "Lead" = "/lead",
  "OperationTask" = "/operation-task",
  "SalesTask" = "/sales-task",
  "Grn" = "/grn",
  "LeadManagemant" = "/lead-managemant",
  "PotentialBusiness" = "/potential-business",
  "Target" = "/target",
  "Specifier" = "/specifier",
  "SalesTarget" = "/sales-target",
  "Sibling" = "/sibling",
  "StoreRmDistribution" = "/store-rm-distribution",
  "SalesDashboard" = "/sales-dashboard",
  "HighChart" = "/high-chart",
  "EChart" = "/e-chart",
  "D3Chart" = "/d3-chart",
  "SalesReviewNumber" = "/number",
  "Detailed" = "/detailed",
  "MoneyCollection" = "/money-collection",
  "Development" = "/development",
  "ActionItem" = "/action-item",
  "PrivateSegment" = "/private-segment/*",
  "GovernmentSegment" = "/government-segment/*",
  "RegentReview" = "/regent-review/*",
  "ResidentialSegment" = "/residential-segment/*",
  "SalesSegmentReview" = "/sales-segment-review",
  "Activity" = "/activity",
  "RegentNumber" = "/regent-number",
  "RegentDevelopment" = "/regent-development",
}

export enum EFieldInputTypes {
  "Select" = "select",
  "SelectSearchable" = "select-searchable",
  "SelectMultiple" = "select-multiple",
  "Datalist" = "datalist",
  "Checkbox" = "checkbox",
  "Checkboxes" = "checkboxes",
  "Radio" = "radio",
  "Button" = "button",
  "Color" = "color",
  "Date" = "date",
  "DateTimeLocal" = "datetime-local",
  "Email" = "email",
  "File" = "file",
  "Hidden" = "hidden",
  "Image" = "image",
  "Month" = "month",
  "Number" = "number",
  "Password" = "password",
  "Range" = "range",
  "Reset" = "reset",
  "Search" = "search",
  "Submit" = "submit",
  "Tel" = "tel",
  "Text" = "text",
  "TextEditor" = "text-editor",
  "Time" = "time",
  "Url" = "url",
  "Week" = "week",
  "TextArea" = "textarea",
  "Custom" = "custom",
}

export interface IOnChangeEvent {
  target: { name: string; value: unknown; type?: EFieldInputTypes; checked?: boolean };
}

export type ExtraToChangeOnChangeForm = (form: IFormData, event: IOnChangeEvent) => { [k: string]: any };

export type OnChangeHandler = (event: IOnChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, extraToChange?: {}, fieldData?: Partial<IField>) => void;

export interface IInitialForm {
  [k: string]: any;
}
export interface IInitialSearchForm {
  [k: string]: any;
}

export interface IFieldDependency<N = string> {
  name: N;
  initialValue?: string | number | null;
  viewName?: string;
  getOptionsConfig?: IGetOptionsConfig;
}

export interface IFieldProps<Name = string, Value = any> {
  id?: string;
  name: Name;
  title: string;
  placeholder?: string;
  initialValue?: string | number | null;
  value?: Value;
  checked?: boolean;
  defaultValue?: string;
  multiple?: boolean | ((form: IFormData) => boolean);
  viewValue?: string;
  accept?: string;
  onChange?: OnChangeHandler;
  onChange1?: OnChangeHandler;
  required?: boolean | ((form: IFormData) => boolean);
  requiredInSearch?: boolean;
  setter?: (value: Value, form: IFormData) => any;
  dependencies?: Array<IFieldDependency<Name>>;
  hideInFormOnCondition?: (formData: IFormData) => boolean;
  error?: string;
  readOnly?: boolean | ((formData: IFormData) => boolean);
  errors?: any;
  setErrors?: (arg: any) => void;
  step?: string;
}

export interface IFieldTextProps<Name = string, Value = any> extends IFieldProps<Name, Value> {
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  inputType?: EFieldInputTypes;
}

export interface IAddOptionConfig {
  api: (formData: IFormData) => Promise<IResponse>;
  body?: IFormData;
  fields?: IField[];
  modalSize?: EModalSizes;
  modalTitle?: string;
  initializeForm?: (formData: IFormData) => IFormData;
  initializeOption?: (response: IResponse, formData: IFormData) => ISelectOption;
}
export interface IGetOptionsConfig {
  api: (formData: any) => Promise<IResponse>;
  setter: (response: any) => { data: ISelectOption[] };
}

export interface IFieldSelectProps<Name = string, Value = unknown> extends IFieldProps<Name, Value> {
  options?: ISelectOption<Value>[];
  viewName?: string;
  noEmptyOption?: boolean;
  addOptionConfig?: IAddOptionConfig;
  getOptionsConfig?: IGetOptionsConfig;
  asSearchable?: boolean;
  withMultiSelect?: boolean;
  maxLength?: number;
  form?: IFormData;
  optionFilterer?: (
    options: ISelectOption<unknown>[] | undefined,
    form: IFormData
  ) => {
    data: ISelectOption<unknown>[];
  };
}

export type TCellCB<Value = string, Data = any> = (
  value: Value,
  data: Data | IInitialForm,
  index: number,
  tableFeatures: { setRows: React.Dispatch<React.SetStateAction<IRow[]>>; onChangeRows: TOnChangeRows | undefined; setRefresh: React.Dispatch<React.SetStateAction<number>> }
) => any;

export interface IField<Name = string, Value = any, Data = any> extends IFieldTextProps<Name, Value>, IFieldSelectProps<Name, Value> {
  filterable?: boolean;
  withExportButton?: boolean;
  hideInTable?: boolean;
  hideInTableOnCondition?: (rows: IRow[]) => boolean;
  hideInForm?: boolean;
  hideInEditForm?: boolean;
  hideInSearchForm?: boolean;
  notSortable?: boolean;
  notStyle?: boolean;
  col?: number | any;
  searchCol?: number;
  columnWidth?: number;
  cellColor?: string;
  cell?: TCellCB<Value, Data>;
  validate?: (value: Value, data: Data | IInitialForm) => string | Array<any>;
  cellAsImage?: boolean;
  headCellStyle?: CSSProperties;
  cellStyle?: (row: IRow) => CSSProperties;
  renderField?: (arg: IFieldProps<Name, Value>) => any;
  filterInputType?: EFieldInputTypes;
  filterOptions?: ISelectOption[];
  accessibleRoles?: EUserRoleName[];
  fieldLabelInfo?: string;
  showTextOverflow?: boolean;
  leftRightLabel?: boolean;
}

export enum EButtonThemes {
  "Light" = "light",
  "Dark" = "dark",
}

export interface IFormData {
  id?: number;
  [k: string]: unknown;
}

export interface IRowData {
  id?: number;
  [key: string]: unknown;
  _key?: string;
}

export interface IResponse<T = unknown> {
  id?: number;
  error?: string;
  message?: string;
  data?: T;
  responseData?: { [k: string]: any };
  total?: number;
  statusCode?: number;
  [k: string]: any;
}

export interface IPage {
  size: number;
  number: number;
}

export interface IApi<T, D = IFormData> {
  api: (data: D, pagination?: IPage) => Promise<IResponse<T>>;
  body?: IFormData;
}

export enum EApiNames {
  "Create" = "create",
  "Update" = "update",
  "Delete" = "delete",
  "EnableDisable" = "enabled_disable",
  "Restore" = "restore",
  "DeleteBatch" = "deleteBatch",
  "RestoreBatch" = "restoreBatch",
  "Remove" = "remove",
  "Get" = "get",
  "GetAll" = "getAll",
}
export interface IApis<T = IRow> {
  [EApiNames.Create]?: IApi<{ id: number }>;
  [EApiNames.Update]?: IApi<unknown>;
  [EApiNames.Delete]?: IApi<unknown>;
  [EApiNames.Restore]?: IApi<unknown>;
  [EApiNames.EnableDisable]?: IApi<unknown>;
  [EApiNames.DeleteBatch]?: IApi<unknown>;
  [EApiNames.RestoreBatch]?: IApi<unknown>;
  [EApiNames.Remove]?: IApi<unknown>;
  [EApiNames.GetAll]?: IApi<T[]>;
  [EApiNames.Get]?: IApi<T>;
}

export interface IAction<T, P = unknown> {
  type: T;
  payload?: P;
}

export interface ISelectOption<V = number | string> {
  boq_name?: string;
  label: string;
  value?: V;
  col?: number | string;
  extraToChange?: {
    [k: string]: any;
  };
  input?: string;
  color?: string;
  backgroundColor?: string;
  asGroup?: boolean;
  options?: { label: string; value: string }[];
}
